import React, { useState, useEffect } from 'react'
import { AppHeader } from '@shared/components/appHeader/AppHeader'
import { translate } from '@utils/translationUtils'
import { AppLayout } from '@shared/components/appLayout/AppLayout'
import { showErrorMessage } from '@utils/notificationUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form } from '@shared/components/form/Form'
import { ClientFormConfig } from './configurations/ClientFormConfig'
import { ClientFormValidator } from './configurations/ClientFormValidator'
import { StyledInput } from '@shared/components/form/StyledInput'
import {
    InputMessage,
    TextArea,
} from '@shared/components/form/styledComponents'
import { ClientFormCheckboxes } from './components/ClientFormCheckboxes'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { createBooking } from '@store/reducers/booking'
import { getRouteWithBookingId } from '@utils/routeUtils'
import { Routes } from '@shared/constants/routes'
import { useHistory } from 'react-router'
import {
    diggerPageView,
    diggerSaveData,
    diggerSubmission,
    diggerDental,
    dentalMediadigger,
} from '@store/reducers/digger'
import { Box, Grid } from '@material-ui/core'
import { PrimaryButton } from '@shared/components/buttons/PrimaryButton'
import { rebookBooking } from '@store/reducers/booking'
import { SsnInput } from '@shared/components/form/SsnInput'
import treatment from '@containers/treatment/index'
import { envConfig } from '@infrastructure/envConfig'

const ClientInfo = ({ store, dispatch, activeStep, decreaseStep }) => {
    const history = useHistory()
    const {
        selectedClinic: { id, name },
        language,
    } = store.clinics

    const { end, start, practitioner } = store.timeSlots.selectedTimeSlot
    const treatmentId = store.treatments.selectedTreatment?.id
    const groupClinicId = store.timeSlots.selectedTimeSlot.opus_clinic_id
    const price = store.treatments.selectedTreatment.price
    const loading = store.booking.loading
    const opusDentalId = store.timeSlots.selectedTimeSlot.treatment_id
    const { digger } = store.digger
    const bookingStore = store.booking
    const { isRebookFlow, bookingDetails } = bookingStore

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ClientFormValidator),
    })
    const [ssn, setSsn] = useState('')

    const onSubmit = async data => {
        if (isRebookFlow) {
            const rebookData = {
                time_start: start,
                time_end: end,
                practitioner_id: practitioner.id,
                opus_treatment_id: opusDentalId,
                opus_clinic_id: groupClinicId,
                clinic_id: id,
                ssn: ssn,
            }
            const res = await dispatch(
                rebookBooking(rebookData, bookingDetails.url)
            )

            history.push(
                getRouteWithBookingId(Routes.bookingResults, res.value.data.url)
            )
        } else {
            try {
                data.phone = '+' + data.phone
                data.clinic_id = id
                data.opus_clinic_id = groupClinicId
                data.treatment_id = treatmentId
                data.opus_treatment_id = opusDentalId
                data.practitioner_id = practitioner.id
                data.time_start = start
                data.time_end = end
                data.dentist_fear = getCheckboxValue('dentist_fear')
                data.marketing_approve = getCheckboxValue('marketing_approve')
                data.waiting_list = getCheckboxValue('waiting_list')
                data.dataprocess_consent = getCheckboxValue(
                    'dataprocess_consent'
                )
                data.od = digger.adCode

                const res = await dispatch(createBooking(data))

                if (res.action.type == 'CREATE_BOOKING_FULFILLED') {
                    const form = {}
                    const googleClickId = {}
                    const facebookClickId = {}

                    digger.path = 'bookingResult'

                    digger.clinic = store.clinics.selectedClinic.name
                    digger.income = price
                    const diggerRes = await dispatch(diggerSubmission(digger))

                    if (
                        diggerRes.value.data.data.formSubmissionId &&
                        envConfig.ENVIRONMENT === 'production'
                    ) {
                        const diggerInfo = {}
                        diggerInfo.clinic = store.clinics.selectedClinic.name
                        diggerInfo.phone = data.phone.slice(3)
                        diggerInfo.digger_id =
                            diggerRes.value.data.data.formSubmissionId
                        dispatch(dentalMediadigger(diggerInfo))
                    }
                }

                history.push(
                    getRouteWithBookingId(
                        Routes.bookingResults,
                        res.value.data.url
                    )
                )
            } catch (error) {
                showErrorMessage(error)
            }
        }
    }

    const [patientCheckboxes, setPatientCheckboxes] = useState([
        {
            name: 'dentist_fear',
            isChecked: false,
            label: translate('fearOfDentist'),
        },
        {
            name: 'waiting_list',
            isChecked: false,
            label: translate('earlierTimeslots'),
        },
        {
            name: 'marketing_approve',
            isChecked: false,
            label: translate('emailApprove'),
        },
        {
            name: 'dataprocess_consent',
            isChecked: false,
            isRequired: true,
            label: translate('consentToDataProcessing'),
        },
    ])
    const onCheckboxChange = checkboxName => {
        const changedCheckboxes = patientCheckboxes.map(checkbox => ({
            ...checkbox,
            isChecked:
                checkbox.name === checkboxName
                    ? !checkbox.isChecked
                    : checkbox.isChecked,
        }))
        setPatientCheckboxes(changedCheckboxes)
    }

    const getCheckboxValue = checkboxName =>
        patientCheckboxes.find(({ name }) => name === checkboxName)?.isChecked

    const isRequiredChecked = getCheckboxValue('dataprocess_consent')

    useEffect(() => {
        digger.path = 'bookingInfo'
    }, [])

    const handleInputChange = event => {
        setSsn(event.target.value)
    }

    const checkSsnLength = () => ssn.length < 10

    return (
        <AppLayout loading={loading} activeStep={0}>
            <AppHeader {...{ activeStep, decreaseStep }} headerText={name} />
            {isRebookFlow ? (
                <div>
                    <SsnInput
                        value={ssn}
                        onConfirm={handleInputChange}
                    ></SsnInput>
                    <Box mt={4} display="flex" justifyContent="center">
                        <PrimaryButton
                            onClick={onSubmit}
                            text={translate('rebook')}
                            disabled={checkSsnLength()}
                        />
                    </Box>
                </div>
            ) : (
                <Form
                    {...{
                        errors,
                        control,
                        isDisabled: !isRequiredChecked,
                        buttonText: translate('sendBooking'),
                        onConfirm: handleSubmit(onSubmit),
                        formConfig: ClientFormConfig(language),
                    }}
                >
                    <InputMessage>
                        {translate('markedFields')}{' '}
                        <span style={{ color: 'red' }}>*</span>{' '}
                        {translate('areMandatory')}
                    </InputMessage>
                    <ClientFormCheckboxes
                        {...{ patientCheckboxes, onCheckboxChange }}
                    />

                    <StyledInput
                        label={translate('needToKnow')}
                        fieldProps={{
                            name: 'booking_notes',
                            as: TextArea,
                        }}
                        {...{
                            errors,
                            control,
                            register,
                        }}
                    />
                </Form>
            )}
        </AppLayout>
    )
}

export default withConnectedStore(ClientInfo)
