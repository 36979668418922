import React from 'react'
import { MaterialIcon } from '@shared/components/MaterialIcon'
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'
import { PhoneNumber, Text } from '../styledComponents'
import GoogleMapReact from 'google-map-react'
import { Marker } from '@shared/components/googleMap/Marker'
import { GoogleMapsWrapper } from '@shared/components/googleMap/styledComponents'
import { mapStyles } from '@shared/components/googleMap/mapStyles'
import { envConfig } from '@infrastructure/envConfig'

export const HowArrive = ({ clinic }) => {
    const { lat, long, name, address1, zip, city, tips, phone } = clinic

    return (
        <>
            {lat && (
                <GoogleMapsWrapper>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: envConfig.GOOGLE_MAP_KEY,
                        }}
                        mapHeight={150}
                        defaultZoom={17}
                        defaultCenter={{
                            lat,
                            lng: long,
                        }}
                        options={{
                            styles: mapStyles,
                        }}
                    >
                        <Marker {...{ lat, lng: long, key: lat + long }} />
                    </GoogleMapReact>
                </GoogleMapsWrapper>
            )}

            <Text mb={3}>{name}</Text>
            <Text mb={3}>{`${address1}, ${zip} ${city}`}</Text>

            {phone && (
                <PhoneNumber href={`tel:${phone}`}>
                    <MaterialIcon Icon={LocalPhoneIcon} />
                    {phone}
                </PhoneNumber>
            )}

            {!!tips?.length && (
                <ul>
                    {tips.map(({ id, description }) => (
                        <li key={id}>
                            <Text>{description}</Text>
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}
