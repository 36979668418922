import React, { useState } from 'react'
import { Loader } from '../loader/Loader'
import { PageContainer } from '../styledComponents'
import { SecondaryButton } from '../buttons/SecondaryButton'
import { translate } from '@utils/translationUtils'

export const AppLayout = ({ children, loading, activeStep }) => {
    const backButtonText = translate('backButtonText')

    const sendtoWebsite = function () {
        window.location.href = 'https://odontia.no/'
    }

    return (
        <PageContainer loading={loading ? 1 : 0}>
            {activeStep <= 2 && (
                <SecondaryButton
                    activeStep={activeStep}
                    style="purple"
                    text={backButtonText}
                    onClick={sendtoWebsite}
                />
            )}
            <Loader loading={loading}>{children}</Loader>
        </PageContainer>
    )
}
