import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../i18n/i18n'

export const translate = (translationKey, simpleString = false) => {
    if (simpleString) {
        return i18n.t(translationKey)
    }
    return <Trans i18nKey={translationKey} components={[<b />]} />
}

export const MultiLanguageHelper = () => {
    const currentLngKey = 'currentLanguage'
    const { i18n } = useTranslation()

    const getCurrentLanguage = () => {
        const language = localStorage.getItem(currentLngKey) || i18n.language

        return language
    }

    const setLanguage = lng => {
        localStorage.setItem(currentLngKey, lng)
        i18n.changeLanguage(lng)
    }

    return { getCurrentLanguage, setLanguage }
}
