import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { baseActions } from '@store/baseActions'
import { getHeaders, getJSONData } from '@utils/reguestUtils'
import { APIUrlProvider } from '@store/apiUrlProviders/APIUrlProvider'
import { baseReducer } from '@store/baseReducers'

const initialState = {
    loading: false,
    bookingDetails: {
        clinic: {},
        treatment: {},
        practitioner: {},
    },
    isRebookFlow: false,
}

const apiUrlProvider = new APIUrlProvider()

// ACTIONS
export const createBooking = createAction('CREATE_BOOKING', data => {
    const url = apiUrlProvider.bookingUrl()
    const formData = getJSONData(data)

    return {
        payload: Axios.post(url, formData, getHeaders()),
    }
})

export const getBookingDetails = createAction('GET_BOOKING_DETAILS', id => ({
    payload: Axios.get(apiUrlProvider.bookingDetailsUrl(id)),
}))

export const cancelBooking = createAction(
    'CANCEL_BOOKING',
    (data, bookingId) => {
    const formData = getJSONData(data)
    const url = apiUrlProvider.cancelBookingUrl(bookingId)
    return{
        payload: Axios.post(url, formData, getHeaders()),
    }
})

export const rebookBooking = createAction(
    'REBOOK_BOOKING',
    (data, bookingId) => {
        const formData = getJSONData(data)
        const url = apiUrlProvider.rebookBookingUrl(bookingId)

        return {
            payload: Axios.post(url, formData, getHeaders()),
        }
    }
)

export const enableRebookFlow = createAction('ENABLE_REBOOK_FLOW')

// REDUCERS
const reducer = {
    [createBooking]: baseReducer,
    [rebookBooking]: baseReducer,
    [cancelBooking]: {
        ...baseActions,
        FULFILLED: state => ({
            ...state,
            loading: false,
            isRebookFlow: false,
        }),
    },
    [getBookingDetails]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => ({
            ...state,
            bookingDetails:
                payload.data.success === false
                    ? { ...state.bookingDetails, ...payload.data }
                    : payload.data,
            loading: false,
        }),
    },
    [enableRebookFlow]: store => ({
        ...store,
        isRebookFlow: true,
    }),
}

export default typeToReducer(reducer, initialState)
