import React from 'react'
import { ImageSources } from '@shared/constants/imageSources'
import { translate } from '@utils/translationUtils'
import { SecondaryButton } from '@shared/components/buttons/SecondaryButton'
import { PractitionerImg, PractitionerWrapper, Text } from '../styledComponents'
import dayjs from 'dayjs'
import Box from '@material-ui/core/Box'

export const PractitionerSection = ({ toggleModal, bookingDetails }) => {
    const {
        start,
        practitioner: { name, image },
        treatment: {title}
    } = bookingDetails
    
    const treatmentDate = dayjs(start).format('DD/MM/YYYY HH:mm')

    return (
        <PractitionerWrapper>
            <Text centered={true}>{translate('yourAppointment')}</Text>

            

            <Box fontSize={14}>{title}</Box>
            <Box fontSize={20}>{treatmentDate}</Box>
            <Box fontSize={14} mb={3}>
                {translate('at')} {name}
            </Box>
            <PractitionerImg src={image || ImageSources.defaultAvatarSrc} />
            <SecondaryButton
                onClick={toggleModal}
                text={translate('cancelRebookAppointment')}
            />
        </PractitionerWrapper>
    )
}
