import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const CenteredVerticalFlex = css`
    display: flex;
    align-items: center;
`

export const CenteredFlex = css`
    ${CenteredVerticalFlex};
    justify-content: center;
`

export const PageContainer = styled.div`
    padding: 0 10px 16px 10px;
    margin: 0 7px;
    min-height: 600px;
    position: relative;

    &::before {
        display: ${({ loading }) => (loading ? 'block' : 'none')};
        content: '';
        position: absolute;
        background-color: rgba(255, 255, 255, 0.7);
        width: 98%;
        height: 100%;
        z-index: 98;
    }
`

export const NotFoundTitle = styled.h2`
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    margin-top: 0;
`
