import React, { useEffect } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { AppRoute } from '@shared/components/appRoute/AppRoute'
import { Routes } from '@shared/constants/routes'
import OnlineBooking from '@containers/onlineBooking/index'
import { toastConfig } from '@utils/notificationUtils'
import { toast } from 'react-toastify'
import BookingResults from '@containers/bookingResults/index'
import NotFound from '@shared/components/404/NotFound'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { MultiLanguageHelper } from '@utils/translationUtils'

const App = ({ store }) => {
    const { clinics, booking } = store
    const { setLanguage, getCurrentLanguage } = MultiLanguageHelper()

    const currentLanguage = getCurrentLanguage()
    const language =
        clinics.language || booking.bookingDetails.language || currentLanguage

    useEffect(() => {
        toast.configure(toastConfig)
    }, [])

    useEffect(() => {
        setLanguage(language)
    }, [language])

    return (
        <BrowserRouter>
            <Switch>
                <AppRoute
                    path={Routes.onlineBooking}
                    component={OnlineBooking}
                    exact
                />
                <AppRoute
                    path={Routes.bookingResults}
                    component={BookingResults}
                />
                <AppRoute path="*" component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}

export default withConnectedStore(App)
