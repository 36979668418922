import { translate } from '@utils/translationUtils'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const ClientFormConfig = language => [
    {
        id: 0,
        label: translate('phoneNumber'),
        element: PhoneInput,
        isRequired: true,
        fieldProps: {
            name: 'phone',
            country: language || 'dk',
            inputClass: 'country-code__input',
            buttonClass: 'country-code__button',
            countryCodeEditable: false,
        },
    },
    {
        id: 1,
        label: translate('firstName'),
        isRequired: true,
        fieldProps: {
            name: 'first_name',
        },
    },
    {
        id: 2,
        label: translate('lastName'),
        isRequired: true,
        fieldProps: {
            name: 'last_name',
        },
    },
    {
        id: 3,
        label: translate('email'),
        fieldProps: {
            name: 'email',
        },
    },
    {
        id: 4,
        label: translate('socialSecurityNumber'),
        message: translate('dataEncrypted'),
        isRequired: true,
        fieldProps: {
            name: 'ssn',
        },
    },
]
