import styled from '@emotion/styled'
import { Colors } from '@shared/constants/colors'

const MapMarker = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -100%);
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        position: absolute;
        display: block;
        content: '';
        width: 30px;
        height: 30px;
        background: url(${({ imgSrc }) => imgSrc}) center / contain no-repeat;
    }
`

const GoogleMapsWrapper = styled.div`
    height: 150px;
    width: 100%;
    border: 1px solid ${Colors.green[300]};
    border-radius: 16px;
    overflow: hidden;
    margin: 10px 0;
`

export { MapMarker, GoogleMapsWrapper }
