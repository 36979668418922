import typeToReducer from 'type-to-reducer'
import Axios from 'axios'
import { createAction } from '@reduxjs/toolkit'
import { baseActions } from '@store/baseActions'
import { APIUrlProvider } from '@store/apiUrlProviders/APIUrlProvider'
import { translate } from '@utils/translationUtils'
import { getHeaders, getJSONData } from '@utils/reguestUtils'
import { envConfig } from '@infrastructure/envConfig'


const apiUrlProvider = new APIUrlProvider()

const initialState = {
    digger: {},
    diggerId:{}
}

// ACTIONS
export const diggerPageView = createAction('DIGGER_PAGE_VIEW', data => {
        const config = {
        headers: { Authorization: `Bearer ${envConfig.DIGGER_KEY}` }
    };

    const url = apiUrlProvider.diggerPageView()
    const formData = getJSONData(data)
   

    return {
        payload: Axios.post(url, data, config),
    }
})

export const dentalMediadigger = createAction('DENTAL_MEDIA_DIGGER', data => {
    
   
    const url = apiUrlProvider.dentalMediaDigger()
    const formData = getJSONData(data)
   

    return {
        payload: Axios.post(url, data),
    }
})

export const diggerSaveData = createAction('DIGGER_SAVE_DATA', data => ({
    payload: data,
}))

export const diggerSubmission = createAction('DIGGER_SUBMISSION', data => {
    const config = {
        headers: { Authorization: `Bearer ${envConfig.DIGGER_KEY}` }
    };

    const url = apiUrlProvider.diggerSubmissionUrl()
    const formData = getJSONData(data)
    const params = data

    return {
        payload: Axios.post(url, data, config),

    }
})

// REDUCERS
const reducer = {
    [diggerPageView]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => {
            
            return {
                ...state,
                ...payload.data,
                diggerId:payload.data.id,



            }
        },
    },
    [diggerSubmission]: {
        ...baseActions,
        FULFILLED: (state, { payload }) => {
            
            return {
                ...state,
                ...payload.data,
                diggerId:payload.data.id,
            }
        },
    },
    [diggerSaveData]:
     (state, {payload}) => ({
        ...state,
        digger: payload,

    }),



}

export default typeToReducer(reducer, initialState)