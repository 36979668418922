import { createMuiTheme } from '@material-ui/core'
import { Colors } from '@shared/constants/colors'

export const datePickerTheme = (selectedDate, isMobile) =>
    createMuiTheme({
        overrides: {
            MuiPickersBasePicker: {
                container: {
                    alignItems: 'center',
                    backgroundColor: Colors.green[100],
                    borderRadius: 12,
                },
                pickerView: {
                    minHeight: 380,
                    maxWidth: isMobile ? 325 : '90%',
                    minWidth: isMobile ? 310 : '90%',
                },
            },
            MuiButtonBase: {
                root: { backgroundColor: 'transparent' },
            },
            MuiTypography: {
                body1: {
                    fontSize: 16,
                    fontWeight: 600,
                    color: Colors.text,
                },
                body2: { fontSize: 13 },
                caption: {
                    fontSize: 12,
                    textTransform: 'uppercase',
                    fontWeight: 600,
                },
            },
            MuiPickersCalendarHeader: {
                daysHeader: {
                    justifyContent: isMobile ? 'center' : 'space-between',
                },
                dayLabel: {
                    color: Colors.text,
                    width: isMobile ? 40 : 65,
                },
                iconButton: {
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                switchHeader: {
                    textTransform: 'capitalize',          
                    marginTop: -65,                  
                },
            },
            MuiPickersCalendar: {
                week: {
                    marginBottom: 7,
                    justifyContent: isMobile ? 'center' : 'space-between',
                },
            },
            MuiIconButton: {
                root: { borderRadius: 0 },
            },
            MuiPickersDay: {
                day: {
                    color: Colors.text,
                    width: isMobile ? 40 : 65,
                    height: 40,
                },
                daySelected: {
                    color: Colors.text,
                    backgroundColor: selectedDate
                        ? Colors.green[300]
                        : 'transparent',
                    '&:hover': {
                        backgroundColor: Colors.green[200],
                    },
                },
                dayDisabled: {
                    color: Colors.border,
                },
            },
        },
    })
