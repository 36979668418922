import { withConnectedStore } from '@hocs/withConnectedStore'
import React, { useEffect, useState } from 'react'
import { BookingStepsEnum } from './configurations/BookingStepsEnum'
import { bookingStepsFactory } from './configurations/BookingStepsFactory'

const OnlineBooking = ({ store, scrollTop }) => {
    const { isRebookFlow } = store.booking
    const [activeStep, setActiveStep] = useState(BookingStepsEnum.Clinic)

    const increaseStep = () => setActiveStep(activeStep + 1)
    const decreaseStep = () => setActiveStep(activeStep - 1)

    useEffect(() => {
        scrollTop()
    }, [activeStep])

    useEffect(() => {
        isRebookFlow && setActiveStep(BookingStepsEnum.TimeSlot)
    }, [isRebookFlow])

    return (
        <div>
            {bookingStepsFactory.getActiveStepComponent(activeStep, {
                activeStep,
                increaseStep,
                decreaseStep,
            })}
        </div>
    )
}

export default withConnectedStore(OnlineBooking)
