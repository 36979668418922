import React, { useEffect, useState } from 'react'
import { BookingStepsEnum } from '@containers/onlineBooking/configurations/BookingStepsEnum'
import { AppHeader } from '@shared/components/appHeader/AppHeader'
import { AppLayout } from '@shared/components/appLayout/AppLayout'
import { translate } from '@utils/translationUtils'
import { Text } from './styledComponents'
import { PractitionerSection } from './components/PractitionerSection'
import { useHistory, useParams } from 'react-router'
import { getBookingDetails } from '@store/reducers/booking'
import { withConnectedStore } from '@hocs/withConnectedStore'
import {
    StyledExpansionPanel,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '@containers/clinicSearch/styledComponents'
import { withExpandedPanelsFunctionality } from '@hocs/withExpandedPanelsFunctionality'
import { getAccordionConfig } from './configurations/AccordionConfig'
import { ConfirmationModal } from './components/ConfirmationModal'
import { Routes } from '@shared/constants/routes'
import EmptyPageWithMessage from '@shared/components/emptyPageWithMessage/EmptyPageWithMessage'
import AlreadyCanceledBooking from './components/AlreadyCanceledBooking'
import ConfirmCancellation from '@containers/ConfirmCancellation'


const BookingResults = ({
    store,
    dispatch,
    scrollTop,
    handleChange,
    renderExpandIcon,
    setExpandedPanels,
}) => {
    const { push } = useHistory()
    const { bookingId } = useParams()
    const { loading, bookingDetails } = store.booking
    const accordionConfig = getAccordionConfig(bookingDetails.treatment.tips)

    const [isCanceledBooking, setCanceledBooking] = useState(false)
    const [isCancellingBooking, setCancellingBooking] = useState(false)
    const [isAlreadyCanceledBooking, setAlreadyCanceledBooking] = useState(
        false
    )
    const [isModalOpen, setModalOpen] = useState(false)

    const toggleModal = () => setModalOpen(!isModalOpen)

    const getDetails = async () => {
        try {
            const res = await dispatch(getBookingDetails(bookingId))
            const data = res.value.data

            if (data.success === false) {
                setAlreadyCanceledBooking(true)
            }
        } catch (error) {
            push(Routes.notFound)
        }
    }

    useEffect(() => {
        getDetails()
    }, [])

    useEffect(() => {
        scrollTop()
    }, [isCanceledBooking])

    useEffect(() => {
        const configIds = accordionConfig.map(({ id }) => id)
        setExpandedPanels(configIds)
    }, [bookingDetails.treatment.tips?.length])

    if (isCancellingBooking) {
        return (
            <AppLayout loading={loading}>
                <AppHeader
                    activeStep={BookingStepsEnum.Clinic}
                    headerText={bookingDetails.clinic.name}
                />
                <ConfirmCancellation {...{ setCancellingBooking, setCanceledBooking, dispatch, bookingId }}>

                </ConfirmCancellation>
            </AppLayout>
        )
    }


    if (isCanceledBooking) {
        return (
            <EmptyPageWithMessage
                headerText={bookingDetails.clinic.name}
                buttonText={translate('bookNew')}
            >
                <Text centered mb={40}>
                    {translate('appointmentHasBeenCanceled')}
                </Text>
            </EmptyPageWithMessage>
        )
    }

    if (isAlreadyCanceledBooking) {
        return <AlreadyCanceledBooking />
    }

    return (
        <AppLayout loading={loading}>
            <AppHeader
                activeStep={BookingStepsEnum.Clinic}
                headerText={bookingDetails.clinic.name}
            />

            <PractitionerSection {...{ bookingDetails, toggleModal }} />

            <Text mb={20}>{translate('pleasedToWelcome')}</Text>

            {accordionConfig.map(({ id, title, Component }) => (
                <StyledExpansionPanel
                    key={id}
                    onChange={handleChange(id)}

                >
                    <StyledAccordionSummary expandIcon={renderExpandIcon(id)}>
                        {title}
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        <Component {...bookingDetails} />
                    </StyledAccordionDetails>
                </StyledExpansionPanel>
            ))}

            <ConfirmationModal
                {...{
                    bookingId,
                    isModalOpen,
                    toggleModal,
                    setCanceledBooking,
                    setCancellingBooking,
                    startDate: bookingDetails.start,
                    timezone: bookingDetails.timezone,
                    penaltyFee: bookingDetails.clinic.penalty_fee,
                    cancellationTime: bookingDetails.clinic.cancellation_time,
                }}
            />
        </AppLayout>
    )
}

export default withConnectedStore(
    withExpandedPanelsFunctionality(BookingResults)
)
