const isDevelopmentMode = process.env.NODE_ENV === 'development'
const environment = process.env.REACT_APP_ENVIRONMENT

let API_URL
let API_DENTALMEDIA
if (isDevelopmentMode && environment === 'development') {
    API_URL = 'http://localhost:8000/api'
    API_DENTALMEDIA = 'https://localhost:8000'
} else {
    if (environment === 'staging') {
        API_URL = 'https://api.booking.staging.dentalmedia.io/api'
        API_DENTALMEDIA = 'https://api.booking.staging.dentalmedia.io'
    }
    if (environment === 'production') {
        API_URL = 'https://api.booking.dentalmedia.io/api'
        API_DENTALMEDIA = 'https://api.dentalmedia.dk'
    }
}

export const envConfig = {
    API_URL: API_URL,
    GOOGLE_MAP_KEY: 'AIzaSyDRZpvk0o-pmqHDeCVmCZHpVI7hByB3p7E',
    DIGGER_KEY:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vYXBpLWRpZ2dlci12Mi5kaWd0ZWN0aXZlLmNvbS9kaWdnZXItZGFzaGJvYXJkL2FwaS9hdXRoL2xvbmctbGl2aW5nLXRva2VuIiwiaWF0IjoxNjk0NzgzNzc0LCJleHAiOjE3NTc4NTU3NzQsIm5iZiI6MTY5NDc4Mzc3NCwianRpIjoiWFRwdjlDcHdGY0VkaFUwcSIsInN1YiI6IjMwIiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyIsInVzZXJJZCI6MzAsIm9yZ2FuaXphdGlvbklkIjo2fQ._9pvueuQ1dNp3wpT9Q7CuL2ah_vGMWFxcfcFJ5pvdeY',
    CHAIN_NAME: 'Odontia',
    API_DENTALMEDIA: API_DENTALMEDIA,
    DIGGER_API: 'https://api-digger-v2.digtective.com',
    ENVIRONMENT: environment,
}
