import { withConnectedStore } from '@hocs/withConnectedStore'
import { translate } from '@utils/translationUtils'
import {
    InputMessage,
} from '@shared/components/form/styledComponents'

export const SsnInput = ({ value, onConfirm }) => {


    return (
        <div>
            <h5 style={{
                textAlign: 'center',
                fontWeight: 400,
                margin: '16px 0',
                fontSize: '20px',
            }}>{translate('whySsnTitle')}</h5>
            <form>
                <label style={{
                    display: 'block',
                    marginBottom: '3px',
                    fontSize: '14px',
                    fontWeight: '600',
                }}>{translate('socialSecurityNumber')}</label>
                <input
                    style={{
                        padding: '10px',
                        width: '100%',
                        border: '1px solid transparent',
                        borderRadius: '10px',
                        outline: 'none',
                        backgroundColor: "#EBF0EB"
                    }}
                    type="text"
                    value={value}
                    onChange={onConfirm}
                />
                <InputMessage>
                    {translate('whySsn')}
                </InputMessage>
            </form>
        </div>
    )
}
export default withConnectedStore(SsnInput)