import React from 'react'
import { translate } from '@utils/translationUtils'
import { Text } from '@containers/bookingResults/styledComponents'
import EmptyPageWithMessage from '@shared/components/emptyPageWithMessage/EmptyPageWithMessage'

const AlreadyCanceledBooking = () => (
    <EmptyPageWithMessage buttonText={translate('bookNew')}>
        <Text centered fontsize={14} mb={20}>
            {translate('appointmentHasBeenCanceled')}
        </Text>

        <Text centered fontsize={14} mb={40}>
            {translate('cancelledBookingMessage')}
        </Text>
    </EmptyPageWithMessage>
)

export default AlreadyCanceledBooking
