import React from 'react'
import { AppHeader } from '@shared/components/appHeader/AppHeader'
import { AppLayout } from '@shared/components/appLayout/AppLayout'
import { envConfig } from '@infrastructure/envConfig'
import { BookingStepsEnum } from '@containers/onlineBooking/configurations/BookingStepsEnum'
import { useHistory } from 'react-router'
import { Routes } from '@shared/constants/routes'
import { PrimaryButton } from '../buttons/PrimaryButton'
import { OdontiaButton } from '../buttons/OdontiaButton'
import Box from '@material-ui/core/Box'

const EmptyPageWithMessage = ({ children, headerText, buttonText, isOdontia }) => {
    const { push } = useHistory()

    const redirectToBooking = () => push(Routes.onlineBooking) 

    const redirectToOdontia = () => {
        window.location.href='https://odontia.no/timebestilling';
    }

    return (
        <AppLayout loading={false}>
            <AppHeader
                activeStep={BookingStepsEnum.Clinic}
                headerText={headerText || envConfig.CHAIN_NAME}
            />

            {children}
            {isOdontia
            ? <Box  display="flex" justifyContent="center">
                <OdontiaButton onClick={redirectToOdontia} text={buttonText} />
            </Box>
            
            :<Box display="flex" justifyContent="center">
                <PrimaryButton onClick={redirectToBooking} text={buttonText} />
            </Box>
            }
        </AppLayout>
    )
}

export default EmptyPageWithMessage
