import { envConfig } from '@infrastructure/envConfig'
import { getFormattedDateNumber } from '@utils/dateUtils'
import dayjs from 'dayjs'

export class APIUrlProvider {
    baseUrl = `${envConfig.API_URL}`
    dentalMediaUrl = `${envConfig.API_DENTALMEDIA}`
    diggerUrl = `${envConfig.DIGGER_API}`

    searchUrl = () => `${this.baseUrl}/search`

    treatmentsUrl = clinicId =>
        `${this.baseUrl}/clinics/${clinicId}/client-treatments`

    bookingUrl = () => `${this.baseUrl}/bookings`

    bookingDetailsUrl = id => `${this.baseUrl}/bookings/${id}`

    practitionersUrl = clinicId =>
        `${this.baseUrl}/clinics/${clinicId}/client-practitioners`

    timeSlotsUrl = (date, treatmentId, clinicId, practitionerId, groupId) => {
    
        const selectedDate = dayjs(date)
        const year = selectedDate.get('year')
        const month = getFormattedDateNumber(selectedDate.get('month') + 1)

        let url = `${this.baseUrl}/timeslots?year=${year}&month=${month}&treatment_id=${treatmentId}&clinic_id=${clinicId}`

        if(groupId !== 1) {
            url = `${url}&groupId=${groupId}`
        } 

        if (practitionerId) {
            url = `${url}&practitioner_id=${practitionerId}`
        }
        return url
    }

    cancelBookingUrl = bookingId => `${this.bookingUrl()}/${bookingId}/cancel`

    rebookBookingUrl = bookingId => `${this.bookingUrl()}/${bookingId}/rebooking`

    diggerSubmissionUrl = () => `${this.diggerUrl}/digger-core-api/form-submission`

    diggerPageView = () => `${this.diggerUrl}/page-view`

    dentalMediaDigger = () => `${this.dentalMediaUrl}/digtective/in.php`
}
