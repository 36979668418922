import React, { useEffect } from 'react'
import { AppHeader } from '@shared/components/appHeader/AppHeader'
import { translate } from '@utils/translationUtils'
import {
    StyledExpansionPanel,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '@containers/clinicSearch/styledComponents'
import { withExpandedPanelsFunctionality } from '@hocs/withExpandedPanelsFunctionality'
import { TreatmentItem } from './components/TreatmentItem'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { AppLayout } from '@shared/components/appLayout/AppLayout'
import treatments, {
    getTreatments,
    saveTreatment,
} from '@store/reducers/treatments'
import { TreatmentSubTitle, TreatmentTitle } from './styledComponents'
import { diggerPageView, diggerSaveData } from '@store/reducers/digger'

const Treatment = ({
    store,
    dispatch,
    activeStep,
    increaseStep,
    decreaseStep,
    handleChange,
    renderExpandIcon,
    setExpandedPanels,
}) => {
    const { categories, loading } = store.treatments
    const { id, name, phone } = store.clinics.selectedClinic
    const { digger } = store.digger

    const onTreatmentClick = treatment => () => {
        dispatch(saveTreatment(treatment))
        increaseStep()
    }

    useEffect(() => {
        dispatch(getTreatments(id))
    }, [dispatch, id])

    useEffect(() => {
        digger.path = 'treatments'
    }, [digger])

    const sortedCategories = [...categories]
        .filter(category => category.active === 1)
        .sort((a, b) => a.order - b.order)
        .map(category => ({
            ...category,
            treatments: category.treatments
                ? category.treatments
                      .filter(treatment => treatment.status === 'active')
                      .sort((a, b) => a.order - b.order)
                : [],
        }))

    const otherTreatments =
        categories.find(category => category.name === 'Other')?.treatments || []

    return (
        <AppLayout loading={loading} activeStep={activeStep}>
            <AppHeader {...{ activeStep, decreaseStep }} headerText={name} />

            <TreatmentTitle>{translate('chooseTreatment')}</TreatmentTitle>
            {phone && (
                <TreatmentSubTitle>
                    {translate('treatmentAcuteQuestion')}
                    <br />
                    {translate('callUs')} {phone}
                </TreatmentSubTitle>
            )}

            {sortedCategories.map(({ id, name, treatments }) =>
                treatments?.length ? (
                    <StyledExpansionPanel key={id} onChange={handleChange(id)}>
                        <StyledAccordionSummary
                            expandIcon={renderExpandIcon(id)}
                        >
                            {name}
                        </StyledAccordionSummary>

                        <StyledAccordionDetails>
                            <div>
                                {treatments?.map(item => (
                                    <TreatmentItem
                                        key={item.id}
                                        treatment={item}
                                        onTreatmentClick={onTreatmentClick}
                                    />
                                ))}
                            </div>
                        </StyledAccordionDetails>
                    </StyledExpansionPanel>
                ) : null
            )}

            <div>
                {otherTreatments.map(item => (
                    <TreatmentItem
                        key={item.id}
                        treatment={item}
                        onTreatmentClick={onTreatmentClick}
                    />
                ))}
            </div>
        </AppLayout>
    )
}

export default withConnectedStore(withExpandedPanelsFunctionality(Treatment))
