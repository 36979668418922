import React from 'react'
import { translate } from '@utils/translationUtils'
import { Text } from '@containers/bookingResults/styledComponents'
import { NotFoundTitle } from '../styledComponents'
import EmptyPageWithMessage from '../emptyPageWithMessage/EmptyPageWithMessage'

const NotFound = () => (
    <EmptyPageWithMessage buttonText={translate('bookNew')} isOdontia={true}>
        <NotFoundTitle>{translate('notFound')}</NotFoundTitle>

        <Text centered fontsize={14} mb={40}>
            {translate('notFoundMessage')}
        </Text>
    </EmptyPageWithMessage>
)

export default NotFound
