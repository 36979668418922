import React from 'react'
import { ModalComponent } from '@shared/components/modal/Modal'
import {
    ModalButtonsWrapper,
    Text,
} from '@containers/bookingResults/styledComponents'
import { translate } from '@utils/translationUtils'
import { PrimaryButton } from '@shared/components/buttons/PrimaryButton'
import { PrimaryRedButton } from '@shared/components/buttons/PrimaryRedButton'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { cancelBooking, enableRebookFlow } from '@store/reducers/booking'
import { showErrorMessage } from '@utils/notificationUtils'
import { saveTreatment } from '@store/reducers/treatments'
import { saveClinic } from '@store/reducers/clinics'
import { useHistory } from 'react-router'
import { Routes } from '@shared/constants/routes'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(timezone)

const ConfirmationModal = ({
    store,
    dispatch,
    timezone,
    startDate,
    bookingId,
    penaltyFee,
    isModalOpen,
    toggleModal,
    cancellationTime,
    setCanceledBooking,
    setCancellingBooking,
    increaseStep
}) => {
    const history = useHistory()
    const { bookingDetails } = store.booking
    const { clinic, treatment } = bookingDetails

    const nowTz = dayjs().tz(timezone)
    const startDateTz = dayjs.tz(startDate, timezone)
    const maxCancellationDateTz = startDateTz.subtract(cancellationTime, 'hour')
    const cancellationWithFee = maxCancellationDateTz.diff(nowTz) < 0

    const handleCancelBooking = async () => {
        toggleModal()
        setCancellingBooking(true)
        // try {
        //     toggleModal()
        //     await dispatch(cancelBooking(bookingId))
        //     setCanceledBooking(true)
        // } catch (error) {
        //     showErrorMessage(error)
        // }
    }

    const handleRebookBooking = () => {
        dispatch(saveClinic(clinic))
        dispatch(saveTreatment(treatment))
        dispatch(enableRebookFlow())

        history.push(Routes.onlineBooking)
    }

    return (
        <ModalComponent {...{ isModalOpen, toggleModal }}>
            {!!penaltyFee && cancellationWithFee ?
                  <>
                <Text fontsize={14} mb={16}>
                    {translate('lessThan72Hours')} <br/>  {translate('youCanStillCall')}  {clinic.phone}{' '}
                    {translate('lessThan24Hours')}
                </Text>
                <ModalButtonsWrapper>
                    <PrimaryButton
                    text={translate('goBack')}
                    onClick={toggleModal}
                />
                </ModalButtonsWrapper>
                </>
            :
             
                 <>
            <Text centered={true} fontsize={14} mb={6}>
                {translate('cancelBookingQuestion')}
            </Text>
                
            <ModalButtonsWrapper>
                <PrimaryButton
                    text={translate('wantToRebook')}
                    onClick={handleRebookBooking}
                />
                <PrimaryRedButton
                    text={translate('wantToCancel')}
                    onClick={handleCancelBooking}
                />
                <PrimaryButton
                    text={translate('goBack')}
                    onClick={toggleModal}
                />
            </ModalButtonsWrapper>
            </>
        }
        </ModalComponent>
    )
}

const withStore = withConnectedStore(ConfirmationModal)

export { withStore as ConfirmationModal }
