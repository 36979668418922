import React from 'react'
import { FormControlLabel, FormGroup, Box } from '@material-ui/core'
import { RequiredIco, StyledMUICheckbox } from './styledComponents'

const StyledCheckbox = ({
    handleChange,
    isRequired,
    isChecked,
    label,
    name,
}) => (
    <FormGroup>
        <FormControlLabel
            control={
                <StyledMUICheckbox
                    checked={isChecked}
                    onChange={handleChange}
                    name={name}
                    color="default"
                />
            }
            label={
                <Box component="div" fontSize={14} position="relative">
                    {label}
                    {isRequired && (
                        <RequiredIco top={-2} right={-10}>
                            *
                        </RequiredIco>
                    )}
                </Box>
            }
        />
    </FormGroup>
)

export default StyledCheckbox
