import { string, object } from 'yup'

export const checkDigitsOnly = value => /^\d+$/.test(value)

export const ClientFormValidator = object().shape({
    phone: string()
        .required('Phone Number is required')
        .min(10, 'Incorrect phone number'),
    first_name: string().required('First Name is required'),
    last_name: string().required('Last Name is required'),
    email: string().email('E-mail must be a valid'),
    ssn: string()
        .required('Social Security Number is required')
        .min(11)
        .max(11)
        .matches(/^[0-9]+$/, 'Please enter only numbers')
        .test(
            'Digits only',
            'The field should have digits only',
            checkDigitsOnly
        )
        .label('SNN'),
})
