import React from 'react'
import StyledCheckbox from '@shared/components/form/StyledCheckbox'
import Box from '@material-ui/core/Box'
import { getKey } from '@utils/arrayUtils'

export const ClientFormCheckboxes = ({
    patientCheckboxes,
    onCheckboxChange,
}) => {
    return (
        <Box pl={2} marginY={2}>
            {patientCheckboxes.map(({ name, label, isChecked, isRequired }) => (
                <StyledCheckbox
                    key={getKey()}
                    isRequired={isRequired}
                    isChecked={isChecked}
                    handleChange={() => onCheckboxChange(name)}
                    name={name}
                    label={label}
                />
            ))}
        </Box>
    )
}
