import { withConnectedStore } from '@hocs/withConnectedStore'
import SsnInput from '@shared/components/form/SsnInput'
import React, { useState } from 'react'
import { cancelBooking } from '@store/reducers/booking'
import { showErrorMessage } from '@utils/notificationUtils'
import { Box } from '@material-ui/core'
import { PrimaryRedButton } from '@shared/components/buttons/PrimaryRedButton'
import { translate } from '@utils/translationUtils'

export const ConfirmCancellation = ({ setCancellingBooking, setCanceledBooking, dispatch, bookingId }) => {

    const [ssn, setSsn] = useState('');

    const handleInputChange = (event) => {
        setSsn(event.target.value);
    };

    const handleCancel = async () => {
        try {
            const ssnData = { ssn: ssn }
            const res = await dispatch(cancelBooking(ssnData, bookingId))
            if (res && res.value.data.success) {
                setCancellingBooking(false)
                setCanceledBooking(true)
            } else {
                showErrorMessage(res ? res.message : "Unknown error ocurred")
            }
        } catch (error) {
            showErrorMessage(error)
        }
    }

    const checkSsnLength = () => ssn.length < 10

    return (
        <div>
            <SsnInput value={ssn} onConfirm={handleInputChange}></SsnInput>
            <Box mt={4} display="flex" justifyContent="center">
                <PrimaryRedButton
                    onClick={handleCancel}
                    text={translate('rebook')}
                    disabled={checkSsnLength()}
                />
            </Box>
        </div>
    )
}
export default withConnectedStore(ConfirmCancellation)