import styled from '@emotion/styled'

export const LoaderWrapper = styled.div`
    position: sticky;
    left: 50%;
    top: 50%;
    margin-top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`

export const LoaderContentWrapper = styled.div`
    position: relative;
`
