export const getJSONData = data => {
    const formData = {}

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key]

            if (element) {
                formData[key] =
                    element?.value === undefined ? element : element?.value
            }
        }
    }

    return JSON.stringify(formData)
}

export const getHeaders = () => ({
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
    },
})
